var exports = {};

/**
 * Combines multiple className strings into one.
 */
function joinClasses(className) {
  var newClassName = className || "";
  var argLength = arguments.length;

  if (argLength > 1) {
    for (var index = 1; index < argLength; index++) {
      var nextClass = arguments[index];

      if (nextClass) {
        newClassName = (newClassName ? newClassName + " " : "") + nextClass;
      }
    }
  }

  return newClassName;
}

exports = joinClasses;
export default exports;